import React from 'react';
import clsx from 'clsx';
import s from './style.module.scss';

const heading = (Tag) => {
  const Component = (props) => {
    const className = clsx(props.className, 'hasMargin', 'adaptive');
    return !!props.id && Tag !== 'h1' ? (
      <Tag className={className} {...props}>
        <a href={`#${props.id}`} className={s.linkAnchor}>
          {props.children}
        </a>
      </Tag>
    ) : (
      <Tag className={className} {...props} />
    );
  };

  Component.displayName = Tag;
  return Component;
};

export const h1 = heading('h1');
export const h2 = heading('h2');
export const h3 = heading('h3');
export const h4 = heading('h4');
export const h5 = heading('h5');
export const h6 = heading('h6');
