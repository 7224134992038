import React from 'react';
import { ClickableIcon, Input, IconActionSearch } from '@hse-design/react';

import s from './style.module.scss';

export const NavSearch = (props) => {
  return (
    <div className={s.wrapper} data-testid="nav-search">
      <Input
        {...props}
        right={
          <ClickableIcon
            iconProps={{ icon: IconActionSearch, size: 'medium' }}
          />
        }
        fullWidth
      />
    </div>
  );
};
