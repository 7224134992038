import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useMenus, useCurrentDoc } from 'docz';

import { NavSearch } from '../NavSearch';
import { NavLink } from '../NavLink';
import { NavGroup } from '../NavGroup';

import s from './style.module.scss';

export const Sidebar = React.forwardRef((props, ref) => {
  const [query, setQuery] = useState('');
  const menus = useMenus({ query });
  const currentDoc = useCurrentDoc();
  const currentDocRef = useRef();
  const handleChange = (ev) => {
    setQuery(ev.target.value);
  };
  useEffect(() => {
    if (ref.current && currentDocRef.current) {
      ref.current.scrollTo(0, currentDocRef.current.offsetTop);
    }
  }, []);

  return (
    <>
      <div
        ref={ref}
        className={clsx(s.wrapper, props.className, { open: props.open })}
        data-testid="sidebar"
      >
        <NavSearch
          placeholder="Поиск..."
          value={query}
          onChange={handleChange}
        />
        {menus &&
          menus.map((menu) => {
            if (!menu.route)
              return <NavGroup key={menu.id} item={menu} sidebarRef={ref} />;
            if (menu.route === currentDoc.route) {
              return (
                <NavLink key={menu.id} item={menu} ref={currentDocRef}>
                  {menu.name}
                </NavLink>
              );
            }
            return (
              <NavLink key={menu.id} item={menu}>
                {menu.name}
              </NavLink>
            );
          })}
      </div>
    </>
  );
});
