import React, { useState } from 'react';
import clsx from 'clsx';
import { useConfig } from 'docz';
import Iframe from 'react-frame-component';
import ReactResizeDetector from 'react-resize-detector';

import s from './style.module.scss';

const CLEAR_PADDING = `<style> body { padding: 0; margin: 0; }  </style>`;
const INITIAL_IFRAME_CONTENT = `<!DOCTYPE html><html><head> ${CLEAR_PADDING} </head><body><div></div></body></html>`;

const IframeWrapper = ({ children, style, className }) => {
  const [containerHeight, setHeight] = useState();
  return (
    <Iframe
      initialContent={INITIAL_IFRAME_CONTENT}
      className={clsx(s.wrapper, className)}
      style={{ ...style, height: containerHeight }}
    >
      {children}
      <ReactResizeDetector
        handleHeight
        onResize={({ height }) => {
          setHeight(height);
        }}
      />
    </Iframe>
  );
};

const NormalWrapper = ({ children, style, className }) => {
  return (
    <div className={clsx(s.wrapper, className)} style={style}>
      {children}
    </div>
  );
};

export const Wrapper = ({
  children,
  content,
  useScoping,
  showingCode,
  className,
  ...props
}) => {
  const {
    themeConfig: { useScopingInPlayground }
  } = useConfig();

  const Element =
    useScoping || useScopingInPlayground ? IframeWrapper : NormalWrapper;

  return (
    <Element className={clsx(s.wrapperBorder, s[`_${content}`], className)} {...props}>
      {children}
    </Element>
  );
};
