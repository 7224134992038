import { colorBrand } from '@hse-design/tokens';

export const white = '#FFFFFF';
export const grayUltraLight = '#FCFBFA';
export const grayExtraLight = '#F5F6F7';
export const grayLight = '#CED4DE';
export const gray = '#67788a';
export const grayDark = '#2D3747';
export const grayExtraDark = '#1D2330';
export const dark = '#13161F';
export const blueLight = '#e9f2fa';
export const blue = colorBrand;
export const skyBlue = '#1FB6FF';
export const negative = '#EB4D4B';
