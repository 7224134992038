import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useCurrentDoc } from 'docz';
import { Icon, IconActionArrowDropdownBottom } from '@hse-design/react';
import { NavLink } from '../NavLink';
import { Link } from '../../../common';
import s from './style.module.scss';

export const NavGroup = ({ item, sidebarRef }) => {
  const currentDoc = useCurrentDoc();
  const currentDocRef = useRef();
  const { name, menu } = item;
  const [subheadingsVisible, setShowsubheadings] = useState(
    currentDoc.menu === name
  );
  const toggleSubheadings = () => setShowsubheadings(!subheadingsVisible);
  useEffect(() => {
    if (sidebarRef.current && currentDocRef.current) {
      sidebarRef.current.scrollTo(0, currentDocRef.current.offsetTop);
    }
  }, []);

  return (
    <div className={s.wrapper} data-testid="nav-group">
      <Link
        className={s.title}
        onClick={(e) => {
          e.preventDefault();
          toggleSubheadings();
        }}
      >
        <span>{item.name}</span>
        <span
          className={clsx(s.iconDropdown, { [s._open]: subheadingsVisible })}
        >
          <Icon icon={IconActionArrowDropdownBottom} size={'medium'} />
        </span>
      </Link>
      <div className={s.sublinkWrapper} data-testid="nav-group-links">
        {menu &&
          subheadingsVisible &&
          menu.map((menu, key) => {
            if (currentDoc.route === menu.route) {
              return (
                <NavLink key={key} item={menu} ref={currentDocRef}>
                  {menu.name}
                </NavLink>
              );
            }
            return (
              <NavLink key={key} item={menu}>
                {menu.name}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};
