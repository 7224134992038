import React from 'react';
import s from './style.module.scss';

export function Strong({ children, ...props }) {
  return (
    <strong className={s.strong} {...props}>
      {children}
    </strong>
  );
}
