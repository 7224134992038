/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import {
  Header as HseHeader,
  HeaderHamburger,
  LogoDesignSystem
} from '@hse-design/react';
import { Link } from '../../../common';
import s from './style.module.scss';

export function Header({ onOpen, ...props }) {
  return (
    <HseHeader
      logo={
        <Link to={'/'} variant={'secondary'}>
          <LogoDesignSystem style={{ height: '56px' }} />
        </Link>
      }
      right={<HeaderHamburger onClick={onOpen} className={s.menuIcon} />}
      {...props}
    />
  );
}
