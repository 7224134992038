import React from 'react';
import s from './style.module.scss';

export const MainContainer = ({ children, ...rest }) => {
  return (
    <div className={s.container} {...rest}>
      {children}
    </div>
  );
};
