import React, { useRef, useState } from 'react';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { MainContainer } from '../MainContainer';
import s from './style.module.scss';

export const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const nav = useRef();

  return (
    <div className={s.layout} data-testid="layout">
      <div className={s.main}>
        <Header className={s.header} onOpen={() => setOpen((s) => !s)} />
        <Sidebar
          className={s.sidebar}
          ref={nav}
          open={open}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
          onClick={() => setOpen(false)}
        />
        <div className={s.wrapper}>
          <MainContainer data-testid="main-container">{children}</MainContainer>
        </div>
      </div>
    </div>
  );
};
