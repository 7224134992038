import React from 'react';
import clsx from 'clsx';
import { Link } from '../../../common';
import { useDocs, useCurrentDoc } from 'docz';
import { get } from 'lodash/fp';

import s from './style.module.scss';

const getHeadings = (route, docs) => {
  const doc = docs.find((doc) => doc.route === route);
  const headings = get('headings', doc);
  return headings ? headings.filter((heading) => heading.depth === 2) : [];
};

const getCurrentHash = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  return window.location ? decodeURI(window.location.hash) : '';
};

export const NavLink = React.forwardRef(({ item, ...props }, ref) => {
  const docs = useDocs();
  const current = useCurrentDoc();

  if (item.hidden) {
    return null;
  }

  const to = item.route;
  const headings = docs && getHeadings(to, docs);
  const isCurrent = item.route === current.route;
  const showHeadings = isCurrent && headings && headings.length > 0;
  const currentHash = getCurrentHash();
  return (
    <React.Fragment>
      <div className={s.linkWrapper}>
        <Link
          {...props}
          to={to}
          className={s.link}
          activeClassName="active"
          ref={ref}
          variant={'primary'}
        />
      </div>
      {showHeadings &&
        headings.map((heading) => (
          <div className={s.linkWrapper}>
            <Link
              key={heading.slug}
              to={`${to}#${heading.slug}`}
              className={clsx(s.smallLink, {
                active: currentHash === `#${heading.slug}`
              })}
              variant={'primary'}
            >
              {heading.value}
            </Link>
          </div>
        ))}
    </React.Fragment>
  );
});
