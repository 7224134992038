import React from 'react';
import { Table, TableBody, TableRow } from '@hse-design/react';
import { TableCell } from '@hse-design/react';

import s from './style.module.scss';

export const getDefaultValue = ({ defaultValue, type, flowType }) => {
  const propType = flowType ? flowType : type;
  if (!defaultValue || !defaultValue.value) return null;
  if (defaultValue.value === "''") {
    return '[Empty string]';
  }
  if (propType && propType.name === 'string') {
    return defaultValue.value.replace(/\'/g, '"');
  }
  if (typeof defaultValue.value === 'object' && defaultValue.value.toString) {
    return defaultValue.value.toString();
  }
  return defaultValue.value;
};

export const Prop = ({ propName, prop, getPropType, isToggle }) => {
  if (!prop.type && !prop.flowType) return null;

  const propType = getPropType(prop);
  const defaultValue = getDefaultValue(prop);

  return (
    <TableRow className={s.tr} data-testid="prop">
      <TableCell className={s.description} data-testid="prop-description">
        <div className={s.propName}>
          <span className={s.propNameText}>{propName}</span>
          {prop.required && (
            <strong className={s.propRequired}>обязательный</strong>
          )}
        </div>
        <div className={s.propDescription}>
          {propType && (
            <div>
              <div className="hse-Text_caption-small-bold">Тип:</div>
              <pre className={s.propTypePre}>
                <code className={s.propTypeCode}>{propType}</code>
              </pre>
            </div>
          )}
          <div>{prop.description}</div>
          {defaultValue && (
            <div>
              <div className="hse-Text_caption-small-bold">По-умолчанию:</div>
              <em>{defaultValue}</em>
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export const Props = ({ props, getPropType, isToggle }) => {
  const entries = Object.entries(props);

  return (
    <Table
      className={s.container}
      classes={{ table: s.table }}
      data-testid="props"
    >
      <TableBody>
        {entries.map(([key, prop]) => (
          <Prop
            key={key}
            propName={key}
            prop={prop}
            getPropType={getPropType}
            isToggle={isToggle}
          />
        ))}
      </TableBody>
    </Table>
  );
};
