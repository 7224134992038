import React from 'react';
import { theme, useConfig, ComponentsProvider } from 'docz';

import defaultTheme from './theme';
import components from './components';

const Theme = ({ children }) => {
  return (
    <ComponentsProvider components={components}>
      <div>{children}</div>
    </ComponentsProvider>
  );
};

export default theme(defaultTheme)(Theme);
