import React from 'react';
import { Helmet } from 'react-helmet';
import faviconSrc from '@hse-design/core/lib/assets/favicon/favicon-32x32.png';

export default function Wrapper({ children }) {
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      {children}
    </>
  );
}
