/* eslint react/jsx-key: 0 */
import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';

import prismTheme from '../../theme/prism/light';

export const Code = ({ children, className: outerClassName }) => {
  const [language] = outerClassName
    ? outerClassName.replace(/language-/, '').split(' ')
    : ['text'];

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      language={language}
      theme={prismTheme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={`${outerClassName || ''} ${className}`}
          style={{ ...style, overflowX: 'auto' }}
          data-testid="code"
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span
                  {...getTokenProps({ token, key })}
                  style={{ display: 'inline-block' }}
                />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
